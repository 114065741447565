import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

import Button from "../../buttons/button";
import { MenuBurger, CloseCross } from "../../svg/icons";

const ToggleButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
  font-family: ${p => p.theme.baseFont};
  color: ${p => (p.active === true ? p.theme.white : p.theme.blueDark)};
  font-size: ${p => p.theme.smallestFontSize};
  font-weight: normal;
  letter-spacing: 1.5px;

  span {
    margin-right: 10px;
    margin-top: ${p => (p.active === true ? 0 : `2px`)};
    text-transform: uppercase;
  }
`;

const mobileMenuButton = ({ active, onClick, theme }) => {
  return (
    <>
      {active ? (
        <ToggleButton active={active} onClick={onClick}>
          <span>Close</span>
          <CloseCross size="s" fill={theme.white} />
        </ToggleButton>
      ) : (
        <ToggleButton active={active} onClick={onClick}>
          <span>Menu</span>
          <MenuBurger size="s" fill={theme.blueDark} />
        </ToggleButton>
      )}
    </>
  );
};

mobileMenuButton.propTypes = {
  theme: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTheme(mobileMenuButton);
