// 'Addons' for rebass, found here: https://github.com/rebassjs/extras
import styled from "styled-components";
import { position, zIndex, top, right, bottom, left } from "styled-system";
import Box from "./box";

export const Position = styled(Box)(position, zIndex, top, right, bottom, left);

export const Relative = styled(Position)([]);

Relative.defaultProps = {
  position: `relative`,
};

export const Fixed = styled(Position)([]);

Fixed.defaultProps = {
  position: `fixed`,
};

export const Sticky = styled(Position)([]);

Sticky.defaultProps = {
  position: `sticky`,
};

export const Absolute = styled(Position)`
  /* static sizes */
  ${p => p.size === `s`
    && `
    width: 57px;
    height: 57px;
  `}
  ${p => p.size === `m`
    && `
    width: 93px;
    height: 93px;
  `}
  ${p => p.size === `l`
    && `
    width: 166px;
    height: 166px;
  `} 

  /* Responsive sizes */
  ${p => p.grow
    && `
        width: 57px;
        height: 57px;

        @media (min-width: ${p.theme.screenM}) {
          width: 93px;
          height: 93px;
        }
        @media (min-width: ${p.theme.screenL}) {
          width: 166px;
          height: 166px;
        }
      `}

  ${p => p.growMid
    && `
    width: 57px;
    height: 57px;
  
    @media (min-width: ${p.theme.screenM}) {
      width: 93px;
      height: 93px;
    }
  `}

    ${p => p.hideOnMobile
      && `
      display: none;

      @media (min-width: ${p.theme.screenM}) {
        display: block;
      }
  `}
`;

Absolute.defaultProps = {
  position: `absolute`,
};
