import styled from "styled-components";
import { Text as RebassText } from "rebass";
import { space, maxWidth, textAlign, display, fontFamily } from "styled-system";

const Text = styled(RebassText)(space, maxWidth, textAlign, display, fontFamily);

Text.defaultProps = {
  color: `#666666`,
  mb: [20, null, null, 30, 40],
  fontSize: [18, null, null, null, 20],
  // lineHeight not converting to px (without quotes), so is stated here
  lineHeight: [`32px`, null, null, null, `38px`],
  textAlign: `inherit`,
  mx: `inherit`,
};

export default Text;
