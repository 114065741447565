import { createGlobalStyle } from "styled-components"
import fontFace from "./fontFace"

const GlobalStyles = createGlobalStyle`
  ${fontFace}
  
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    overflow-x: hidden;
    font-size: 18px;
    font-weight: normal;
  }

  body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,p,blockquote,th,td {
    margin: 0;
    padding: 0;
  }

  body {
    box-sizing: content-box;
    color: ${p => p.theme.black};
    font-family: ${p => p.theme.baseFont};
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${p => p.theme.headingFont};
    z-index: 5;
  }

  a {
    text-decoration: none;
    color: ${p => p.theme.greyDark};
  }

  li {
    list-style-type: none;
  }

  img {
    z-index: 5;
  }

  textarea {
    resize: none;
  }

  *:focus {
      -moz-outline-style: 1px solid ${p => p.theme.green};
      outline: 1px solid ${p => p.theme.green};
    }  

  /* HELPER CLASSES */
  .bold, strong, b {
    color: inherit;
    font-weight: normal;
    font-family: ${p => p.theme.headingFont};
  }

  .bold {
    color: ${p => p.theme.blueDark};
    font-family: ${p => p.theme.headingFont};
  }

  .posRelative {
    position: relative;
  }

  .bringToFront {
    z-index: 40;
  }
  
  .hideOnTablet {
    @media only screen and (max-width: ${p => p.theme.screenL}) {
      display: none !important;
    }
  }

  .hideOnMobile {
    @media only screen and (max-width: ${p => p.theme.screenM}) {
      display: none !important;
    }
  }
 `

export default GlobalStyles
