import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import { Link } from "gatsby"
import MobileMenuButton from "./nav/mobileMenuButton"
import { TopTekkersLogo } from "../svg/logos"
import { navLinks } from "../../utils/content/siteNavigation"
import LoginRegisterLinks from "../links/loginRegisterLinks"
import Circle from "./circle"

const Root = styled.div`
  background-color: ${p => p.theme.green};
  z-index: 9999;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* using % here as vw doesn't take scrollbars width into account when theres a verticle scroll on the page */
  width: 100%;
  height: 100vh;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 5;
  padding-left: 20px;
  padding-right: 20px;
  height: 67px;

  a {
    margin: auto 0;
  }
`

const MenuWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;

  a {
    text-decoration: none;
    color: ${p => p.theme.white};
    transition: color 0.5s;

    &:hover {
      color: ${p => p.theme.grey};
    }
  }

  ul {
    height: 100%;
    max-height: 280px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 4.8rem;
  }

  li {
    list-style-type: none;
    font-size: 20px;
  }

  .mobileActiveClass {
    font-family: ${p => p.theme.headingFont};
  }
`

const ButtonsWrap = styled.div`
  text-align: center;
  padding: 0 20px 40px 20px;
`

const MobileMenu = ({ isMenuOpen, onLinkClick, theme }) => {
  return (
    <Root>
      <HeaderWrap>
        <Link to="/" onClick={onLinkClick}>
          <TopTekkersLogo size="ttLarge" fill={theme.white} />
        </Link>

        <MobileMenuButton active={isMenuOpen} onClick={onLinkClick} />
      </HeaderWrap>

      <MenuWrap>
        <ul>
          {navLinks.map(link => {
            return (
              <li key={link.id}>
                <Link
                  to={link.path}
                  onClick={onLinkClick}
                  activeClassName="mobileActiveClass"
                >
                  {link.name}
                </Link>
              </li>
            )
          })}
        </ul>

        <ButtonsWrap>
          <LoginRegisterLinks
            slimFont
            round
            btnColor={theme.white}
            isMenuOpen={isMenuOpen}
          />
        </ButtonsWrap>
      </MenuWrap>

      <Circle
        size="l"
        bgColor={theme.blueDark}
        opacity="0.1"
        top="-70px"
        right="-40px"
      />
      <Circle
        size="m"
        bgColor={theme.blueDark}
        opacity="0.1"
        top="58%"
        right="-45px"
      />
      <Circle
        size="m"
        bgColor={theme.blueDark}
        opacity="0.1"
        top="23%"
        left="-40px"
      />
      <Circle
        size="m"
        bgColor={theme.blueDark}
        opacity="0.1"
        bottom="-50px"
        left="-40px"
      />
    </Root>
  )
}

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onLinkClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(MobileMenu)
