import DisplayLuckyWOFF from "../fonts/FaroWeb-DisplayLucky.woff"
import DisplayLuckyWOFF2 from "../fonts/FaroWeb-DisplayLucky.woff2"

import RegularLuckyWOFF from "../fonts/FaroWeb-RegularLucky.woff"
import RegularLuckyWOFF2 from "../fonts/FaroWeb-RegularLucky.woff2"

export default `
  @font-face {
      font-family: 'regular-lucky';
      font-display: block;
      src:  url(${RegularLuckyWOFF2}) format('woff2'),
            url(${RegularLuckyWOFF}) format('woff');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'display-lucky';
      font-display: block;
      src:  url(${DisplayLuckyWOFF2}) format('woff2'),
            url(${DisplayLuckyWOFF}) format('woff');
      font-weight: normal;
      font-style: normal;
  }
`
