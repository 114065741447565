import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import Image from "../globals/image";
import { AppleAppBadge } from "../svg/logos";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  > * {
    margin: 0 10px 6px 0;
  }
  div {
    width: 133px !important;
  }
  &:last-child {
    margin-right: 0;
  }

  svg {
    text-align: right;
  }

  ${p => p.vert
    && `
    flex-direction: row;

      @media (min-width: ${p.theme.screenS}) {
        text-align: right;
        flex-direction: column;

        > * {
          margin: 0 10px 6px 0;
          margin-right: 0;
        }
        svg {
          margin-left: auto;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

    `}
`;

export const appBadges = graphql`
  fragment appBadges on File {
    childImageSharp {
      fluid(maxWidth: 133) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const AppstoreLinks = ({ vert }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          google: file(relativePath: { eq: "images/appstore-google.png" }) {
            ...appBadges
          }
          amazon: file(relativePath: { eq: "images/appstore-amazon.png" }) {
            ...appBadges
          }
          apple: file(relativePath: { eq: "images/appstore-apple.png" }) {
            ...appBadges
          }
        }
      `}
      render={(data) => {
        const google = data.google.childImageSharp.fluid;
        const amazon = data.amazon.childImageSharp.fluid;
        const apple = data.apple.childImageSharp.fluid;

        return (
          <Root vert={vert}>
            <a
              href="https://apps.apple.com/us/app/toptekkers/id1472343177?ls=1"
              target="_blank"
              rel="noopener noreferer"
            >
              <AppleAppBadge size="m" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.toptekkers.toptekkers&hl=en"
              target="_blank"
              rel="noopener noreferer"
            >
              <Image fluid={google} alt="Google Play app store" />
            </a>
            <a
              href="https://www.amazon.co.uk/dp/B07VD4WZR8/ref=sr_1_1?keywords=toptekkers&qid=1565005829&s=mobile-apps&sr=1-1 "
              target="_blank"
              rel="noopener noreferer"
            >
              <Image fluid={amazon} alt="Amazon app store" />
            </a>
          </Root>
        );
      }}
    />
  );
};

AppstoreLinks.propTypes = {
  vert: PropTypes.bool,
};

export default AppstoreLinks;
