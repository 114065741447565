import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  white-space: nowrap;

  a {
    display: inline-block;
    padding: 10px 20px;

    font-size: ${p => p.theme.smallFontSize};
    border: 2px solid ${p => (p.isMenuOpen ? p.theme.white : p.theme.green)};
    border-radius: ${p => p.theme.borderRadiusRndBtn};
    transition: border 0.5s, background-color 0.5s, color 0.5s;
  }

  a:first-of-type {
    color: ${p => (p.isMenuOpen ? p.theme.white : p.theme.green)};
    background-color: transparent;
    margin-right: 10px;

    &:hover {
      color: ${p => (p.isMenuOpen ? p.theme.grey : p.theme.greenDark)};
      border: 2px solid
        ${p => (p.isMenuOpen ? p.theme.grey : p.theme.greenDark)};
    }
  }

  a:last-of-type {
    color: ${p => (p.isMenuOpen ? p.theme.green : p.theme.white)};
    background-color: ${p => (p.isMenuOpen ? p.theme.white : p.theme.green)};

    &:hover {
      background-color: ${p => p.isMenuOpen ? p.theme.grey : p.theme.greenDark};
      border: 2px solid
        ${p => (p.isMenuOpen ? p.theme.grey : p.theme.greenDark)};
    }
  }
`;

const LoginRegisterButtons = ({ isMenuOpen }) => {
  return (
    <Root isMenuOpen={isMenuOpen}>
      <a href="https://app.toptekkers.club/login">Login</a>

      <a href="https://app.toptekkers.club">Register</a>
    </Root>
  );
};

LoginRegisterButtons.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default LoginRegisterButtons;
