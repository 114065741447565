import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { fontSize, color } from "styled-system";

const Link = styled(GatsbyLink)(fontSize, color);

Link.defaultProps = {
  color: `#666666`,
  fontSize: [18, null, null, null, 20],
};

export default Link;
