import React from "react";

import Icon from "./icon";

export const MoreDots = ({ ...rest }) => (
  <Icon title="More Dots" viewBox="0 0 18 8" {...rest}>
    <path d="M14 2C14 3.10457 14.8954 4 16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2Z" />
    <path d="M7 2C7 3.10457 7.89543 4 9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2Z" />
    <path d="M0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.895431 0 0 0.89543 0 2Z" />
  </Icon>
);

export const PlayButton = ({ ...rest }) => (
  <Icon title="Play Button" viewBox="0 0 16 16" {...rest}>
    <g opacity="0.4">
      <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" />
      <path
        d="M6 4.93425C6 4.5349 6.44507 4.29671 6.77735 4.51823L11.376 7.58397C11.6728 7.78188 11.6728 8.21811 11.376 8.41602L6.77735 11.4818C6.44507 11.7033 6 11.4651 6 11.0657V4.93425Z"
        fill="#ffffff"
      />
    </g>
  </Icon>
);

export const MenuBurger = ({ ...rest }) => (
  <Icon title="Menu Burger" viewBox="0 0 20 9" {...rest}>
    <rect width="20" height="2" rx="1" />
    <rect y="7" width="20" height="2" rx="1" />
  </Icon>
);

export const CloseCross = ({ ...rest }) => (
  <Icon title="Close Menu" viewBox="0 0 16 16" {...rest}>
    <path d="M0.928635 0.928933C1.31916 0.538409 1.95232 0.538409 2.34285 0.928933L15.0708 13.6569C15.4613 14.0474 15.4613 14.6805 15.0708 15.0711C14.6802 15.4616 14.0471 15.4616 13.6566 15.0711L0.928635 2.34315C0.538111 1.95262 0.538111 1.31946 0.928635 0.928933Z" />
    <path d="M15.0708 0.928933C14.6802 0.538409 14.0471 0.538409 13.6566 0.928933L0.928636 13.6569C0.538112 14.0474 0.538111 14.6805 0.928636 15.0711C1.31916 15.4616 1.95233 15.4616 2.34285 15.0711L15.0708 2.34315C15.4613 1.95262 15.4613 1.31946 15.0708 0.928933Z" />
  </Icon>
);

export const Arrow = ({ ...rest }) => (
  <Icon title="Arrow" viewBox="0 0 25 18" {...rest}>
    <path d="M15.8087 17.8661L13.2851 15.3426L18.1275 10.5002H0.5V6.50016H17.2998L13.4048 2.60512L15.9762 0.0336914L22.2851 6.34257L24.8087 8.86612L22.2372 11.4376L15.8087 17.8661Z" />
  </Icon>
);

export const Tick = ({ ...rest }) => (
  <Icon title="Arrow" viewBox="0 0 13 10" {...rest}>
    <path
      d="M4.24264 9.19239L0 4.94975L1.41421 3.53553L4.24264 6.36396L10.6066 0L12.0208 1.41421L4.24264 9.19239Z"
      fill="white"
    />
  </Icon>
);

export const TTShield = ({ ...rest }) => (
  <Icon title="TTShield" viewBox="0 0 88 88" {...rest}>
    <path d="M44.0002 1.05713C55.7874 12.8443 74.8987 12.8443 86.6859 1.05713C86.6859 69.6246 44.0002 86.5649 44.0002 86.5649C44.0002 86.5649 1.31445 69.6246 1.31445 1.05713C13.1016 12.8443 32.213 12.8443 44.0002 1.05713Z" />
  </Icon>
);

export const Bookmark = ({ ...rest }) => (
  <Icon title="Bookmark" viewBox="0 0 12 18" {...rest}>
    <path d="M10.999 15L5.999 10.997L1 15V2.308C1 1.585 1.586 1 2.308 1H9.69C10.413 1 10.999 1.585 10.999 2.308V15Z" />
  </Icon>
);
