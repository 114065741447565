import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Helmet from "react-helmet";
import GlobalStyles from "../../utils/globalStyles";

import MobileMenu from "../globals/mobileMenu";
import theme from "../../utils/theme";
import Header from "../globals/header/header";
import Footer from "../globals/footer/footer";

// Page component ensures footer is always sat at bottom of page
// main will grow to fit content
const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: ${p => (p.bg ? p.bg : p.theme.white)};

  main {
    flex-grow: 1;

    /* padding protrudes from navbar by 39px then 79px */
    padding-top: ${p => (p.navbarPadOnly ? `61px` : `100px`)};
    @media (min-width: ${p => p.theme.screenL}) {
      padding-top: ${p => (p.navbarPadOnly ? `91px` : `170px`)};
    }
  }

  header {
    position: fixed;
    display: Flex;
    justify-content: center;
    width: 100%;
    height: 61px;
    align-content: center;
    align-items: center;

    border-bottom: 1px solid ${p => p.theme.grey};
    background-color: ${p => p.theme.white};

    @media (min-width: ${p => p.theme.screenL}) {
      height: 91px;
    }
  }

  header,
  footer {
    z-index: 20;
    flex-shrink: 0;
  }
`;

const Layout = ({ children, navbarPadOnly, bg }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // useEffect acts as componentDidMount (among others)
  useEffect(() => {
    // 1. Get URL as string
    const urlString = window.location.href;
    // 2. Returns a newly created URL object
    const url = new URL(urlString);
    // 3. Return the first value associated to the given search parameter, and set to variable
    const referrerId = url.searchParams.get(`referrer`);

    // 4. If so set cookies
    if (referrerId !== null) {
      const cookieName = `ttreferrer`;
      const cookieValue = referrerId;
      const tempDate = new Date();
      tempDate.setMonth(tempDate.getMonth() + 12);
      document.cookie = `${cookieName}=${cookieValue};expires=${tempDate};domain=.toptekkers.club;path=/`;
    }
  });

  return (
    <>
      <Helmet>
        {/* Facebook JS SDK https://developers.facebook.com/docs/javascript/quickstart */}
        <script>
          {`
            {window.fbAsyncInit = function() {
              FB.init({
                appId            : "740849599667822",
                autoLogAppEvents : true,
                xfbml            : true,
                version          : "v6.0"
              });
            }};
          `}
        </script>
        <script async defer src="https://connect.facebook.net/en_US/sdk.js" />
      </Helmet>

      <Page navbarPadOnly={navbarPadOnly} bg={bg}>
        <GlobalStyles theme={theme} />
        {isMenuOpen && (
          <MobileMenu
            isMenuOpen={isMenuOpen}
            onLinkClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        )}

        <Header
          isMenuOpen={isMenuOpen}
          onMobileMenuButtonClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        <main>{children}</main>
        <Footer />
      </Page>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navbarPadOnly: PropTypes.bool,
  bg: PropTypes.string,
};

export default Layout;
