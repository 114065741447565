import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button as RebassButton } from "rebass"

import Spinner from "./spinner"
import { Absolute } from "../containers/position"

const Root = styled(RebassButton)`
  position: relative;
  cursor: pointer;
  font-family: inherit;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: ${p => p.theme.transitionDefault};

  ${p =>
    p.loading &&
    `
    color: transparent !important;
    pointer-events: none !important;
  `}

  ${p =>
    p.bg &&
    `
    background-color: ${p.bg};
    padding: 14px 20px;
    `}

    ${p =>
      p.bg &&
      p.bg === `#30B842` &&
      `
      &:hover {
        background-color: #2FA73D;
      }
    `}
`

const Button = ({ children, loading, ...rest }) => {
  return (
    // loading prop is using if/else (1 : 0) to remove a console.log error
    // This is due to issue in styled components, hack and explanation here:
    // https://github.com/styled-components/styled-components/issues/1198
    <Root loading={loading ? 1 : 0} {...rest}>
      {children}
      {loading && (
        <Absolute left={`45%`} top={`calc(50% - 14px)`}>
          <Spinner />
        </Absolute>
      )}
    </Root>
  )
}

Button.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  bg: `transparent`,
}

export default Button
