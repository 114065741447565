import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Root = styled.div`
  width: 140px;

  @media (min-width: ${p => p.theme.screenL}) {
    position: absolute;
    top: 58px;
    right: -40px;
    padding: 30px 20px;
    text-align: center;
    background-color: ${p => p.theme.grey};
    border-radius: ${p => p.theme.borderRadiusSqBtn};

    a {
      text-decoration: none;
      color: ${p => p.theme.greyDark};
    }

    ul li {
      list-style-type: none;
      display: block;
      margin-right: 0;
      margin-bottom: 12px;

      :last-of-type {
        margin-bottom: 0;
      }
    }

    .navbarActiveClass {
      color: ${p => p.theme.green};
    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;

      border-bottom: 8px solid ${p => p.theme.grey};

      position: absolute;
      top: -6px;
      left: 50%;
      margin-left: -8px;
    }
  }
`;

const SubMenu = () => {
  return (
    <Root className="bringToFront">
      <div className="arrow-up" />
      <ul>
        <li>
          <Link to="/faq" activeClassName="navbarActiveClass">
            FAQ
          </Link>
        </li>
        <li>
          <Link to="/blog" activeClassName="navbarActiveClass">
            Blog
          </Link>
        </li>
        <li>
          <Link to="/contact" activeClassName="navbarActiveClass">
            Contact Us
          </Link>
        </li>
      </ul>
    </Root>
  );
};

export default SubMenu;
