import styled from "styled-components";
import PropTypes from "prop-types";
import { Absolute } from "../containers/position";

const Circle = styled(Absolute)`
  border-radius: 50%;

  z-index: 0;
  opacity: ${p => p.opacity};
  background-color: ${p => p.bgColor};
`;

Circle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  opacity: PropTypes.string,
};

export default Circle;
