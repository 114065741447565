import styled from "styled-components";
import { Box as RebassBox } from "rebass";
import {
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  textAlign,
  border,
  background,
  display,
  height,
  opacity,
  overflow,
  zIndex,
} from "styled-system";

const Box = styled(RebassBox)(
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  textAlign,
  border,
  background,
  display,
  height,
  opacity,
  overflow,
  zIndex,
);

export default Box;
