import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import Button from "../../buttons/button"
import Link from "../../links/link"
import SubMenu from "./subMenu"
import { MoreDots } from "../../svg/icons"
import { navLinks } from "../../../utils/content/siteNavigation"

const LinkWrapper = styled.ul`
  display: none;
  position: relative;

  svg {
    height: auto;
  }

  .desktopOnly {
    display: none;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 44%;

    li {
      list-style-type: none;
      font-size: 20px;
    }

    a {
      text-decoration: none;
      color: ${p => p.theme.greyDark};
      transition: color 0.5s;
    }
    a:hover {
      color: ${p => p.theme.greenDark};
    }

    .navbarActiveClass {
      color: ${p => p.theme.green};
    }
  }

  @media (min-width: ${p => p.theme.screenXL}) {
    width: 54%;

    .desktopOnly {
      display: inline-block;
    }
  }
`

const SubMenuLI = styled.li`
  display: none;

  @media (min-width: ${p => p.theme.screenL}) {
    display: inline-block;
  }

  @media (min-width: ${p => p.theme.screenXL}) {
    display: none;
  }
`

const NavbarLinks = ({ theme }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsSubMenuOpen(false)
    document.removeEventListener(`click`, closeMenu)
  }

  if (isSubMenuOpen) {
    document.addEventListener(`click`, closeMenu)
  }

  const dotColor = isSubMenuOpen ? theme.green : theme.greyDark

  return (
    <LinkWrapper>
      {navLinks.map(link => {
        return (
          // Class conditional used here for arranging the navarbar links on responsive design
          <li key={link.id} className={link.id > 2 ? `desktopOnly` : undefined}>
            <Link to={link.path} activeClassName="navbarActiveClass">
              {link.name}
            </Link>
          </li>
        )
      })}

      <SubMenuLI>
        <Button to="/" onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}>
          <MoreDots size="s" fill={dotColor} />
        </Button>

        {isSubMenuOpen && <SubMenu />}
      </SubMenuLI>
    </LinkWrapper>
  )
}

NavbarLinks.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(NavbarLinks)
